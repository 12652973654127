import React from 'react';
import { useHistory } from 'react-router';
import {
  Badge,
  Button,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiTitle,
  Spinner,
} from 'ui';
import { useGetSupportTicketsPageQuery } from '@app/graphql/queries/support/__generated__/getSupportTicketsPage.generated';
import {
  BinaryOperator,
  OrgInfo,
  SortOrder,
  SupportTicket,
  TicketStatus,
} from '@app/graphql/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { useChatContext } from '../Chat/ChatProvider';
import EmptyMessage from '../EmptyMessage/EmptyMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export const SupportSummary = () => {
  const history = useHistory();

  const { data, loading, error } = useGetSupportTicketsPageQuery({
    variables: {
      input: {
        cursor: '',
        filter: [
          {
            name: 'customFields.status',
            operator: BinaryOperator.NotIn,
            values: [TicketStatus.Closed, TicketStatus.Resolved],
          },
        ],
        filteredByUser: true,
        includeClosedTickets: null,
        pageSize: 5,
        sort: [{ attributeName: 'ts', order: SortOrder.Descending }],
      },
    },
  });

  const { roomSubscriptionsMap } = useChatContext();

  const columns: Array<EuiBasicTableColumn<SupportTicket>> = [
    {
      field: 'title',
      name: 'Title',
      render: (title: string, ticket) => {
        const subscription = roomSubscriptionsMap?.[ticket.chatRoomID];
        return (
          <EuiFlexGroup alignItems="center" justifyContent="flexStart">
            <EuiFlexItem>{title}</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <Badge
                color="primary"
                label={`${subscription?.unread}`}
                hidden={!subscription?.unread}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      },
    },
    {
      field: 'org',
      name: 'Account Name',
      render: (org: OrgInfo) => org?.name || 'None',
      width: '20%',
    },
    {
      field: 'assigneeEmails',
      name: 'Assigned to',
      render: (v) => v?.join(', ') || 'No one',
      width: '20%',
    },
    {
      field: 'status',
      name: 'Status',
      width: '10%',
    },
  ];

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage message={getErrorMessage(error)} />;
  }

  if (data?.supportTicketsPage?.tickets?.length === 0 && !loading) {
    return (
      <EuiPanel paddingSize="l">
        <EuiTitle size="xs">
          <p>Support Messages</p>
        </EuiTitle>
        <EmptyMessage subText="No open support tickets" />
      </EuiPanel>
    );
  }

  const findTickets = (tickets: Array<SupportTicket>, pageIndex: number, pageSize: number) => {
    let pageOfItems;

    if (!pageIndex && !pageSize) {
      pageOfItems = tickets;
    } else {
      const startIndex = pageIndex * pageSize;
      pageOfItems = tickets.slice(startIndex, Math.min(startIndex + pageSize, tickets.length));
    }

    return {
      pageOfItems,
      totalItemCount: tickets.length,
    };
  };

  const { pageOfItems } = findTickets(
    // FIX ME
    // @ts-ignore
    data?.supportTicketsPage?.tickets || [],
    0,
    2,
  );

  return (
    <EuiPanel paddingSize="l">
      <EuiTitle size="xs">
        <p>Support Messages</p>
      </EuiTitle>
      <EuiBasicTable
        style={{ overflowY: 'auto' }}
        columns={columns}
        loading={loading}
        items={pageOfItems}
        rowProps={(row) => ({
          onClick: () => {
            history.push(`/support/${row.chatRoomID}`);
          },
          style: {
            fontWeight: roomSubscriptionsMap?.[row.chatRoomID]?.unread ? 'bold' : 'unset',
          },
        })}
      />
      <EuiFlexItem data-testid="support-view-all" grow={false}>
        <Button
          onClick={() => {
            history.push('/support');
          }}
          size="s"
          label={'View All'}
        />
      </EuiFlexItem>
    </EuiPanel>
  );
};
