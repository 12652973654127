import React from 'react';
import { EuiBasicTable, EuiSpacer } from 'ui';
import { NoChartContainer } from '@app/cx/Stream/Aside/Explorers/Charts/AalScatter';
import { formatStreamCurrency } from '@app/cx/Stream/utils';
import { StyledChartTitle } from './DashboardV2';
import { StyledContainer } from './SummaryStats';

export const formatPercentage = (value) => {
  if (value < 1 && value > 0) {
    return Math.round(value * 1000) / 1000;
  } else {
    return Math.round(value);
  }
};

export const ExposureByPeril = ({ aalData = [] }) => {
  if (aalData.length === 0) {
    return (
      <>
        <StyledContainer>
          <StyledChartTitle>AAL & Exposure By Peril</StyledChartTitle>
        </StyledContainer>
        <EuiSpacer size="m" />
        <StyledContainer>
          <NoChartContainer />
        </StyledContainer>
      </>
    );
  }
  const cols = [
    {
      field: 'peril',
      name: 'Peril',
      render: (item) => <strong>{item}</strong>,
    },
    {
      field: 'latestPerilAAL',
      name: 'AAL',
      render: (item) => <strong>{item ? formatStreamCurrency(item) : '-'}</strong>,
    },
    {
      field: 'perilExposedTIV',
      name: 'Exposed TIV',
      render: (item) => <strong>{item ? formatStreamCurrency(item) : '-'}</strong>,
    },
    {
      field: 'lossRatio',
      name: 'Loss Ratio',
      render: (item) => <strong>{formatPercentage(item)}%</strong>,
    },
  ];

  return (
    <>
      <StyledContainer>
        <StyledChartTitle>AAL & Exposure By Peril</StyledChartTitle>
      </StyledContainer>
      <EuiSpacer size="m" />
      <StyledContainer>
        <EuiBasicTable items={aalData} columns={cols} />
      </StyledContainer>
    </>
  );
};
