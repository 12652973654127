import React from 'react';
import styled from '@emotion/styled';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from 'ui/dist/eui';
import { getChart } from '@app/cx/Stream/Aside/Explorers/chartCatalog';
import ReefMapV2 from '@app/cx/Stream/Reef/ReefMapV2/ReefMapV2';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useGetStreamPropertyGroupsQuery } from '@app/graphql/queries/streams/__generated__/StreamPropertyGroups.generated';
import useStandardQuery from '@app/hooks/useStandardQuery';
// @ts-ignore
import GET_EXPLORERS from '@app/queries/explorers/getExplorers.gql';
import LoadingSpinnerV2 from '../LoadingSpinnerV2/LoadingSpinnerV2';
import { ExposureByPeril } from './ExposureByPeril';
import { StyledContainer, SummaryStats } from './SummaryStats';

const StyledDiv = styled.div`
  padding-bottom: 12px;
  height: 390px;
  border-radius: 6px;
  width: 95%;
  @media (max-width: 900px) {
    height: 250px;
    padding: 0;
  }
`;

export const StyledChartTitle = styled.h2`
  color: rgb(70, 78, 86);
  font-size: 17px;
  font-weight: 400;
`;

export const DashboardV2 = () => {
  const { stream } = useStreamContext();

  if (!stream?.slug) {
    return null;
  }

  const { data: dataGroups } = useGetStreamPropertyGroupsQuery({
    variables: {
      changesSince: '',
      compareChanges: false,
      currentSnapshot: '',
      // NOTE: The API only supports grouping on 1 single attribute.
      groupBy: 'country',
      streamSlug: stream.slug,
    },
  });

  const { data: explorerData, loading } = useStandardQuery(GET_EXPLORERS, {
    variables: {
      fromSnapshot: null,
      spotlight: true,
      streamSlug: stream.slug,
      toSnapshot: '',
    },
  });

  const group = {
    ...dataGroups?.streamPropertyGroups?.groups,
    id: dataGroups?.streamPropertyGroups?.groups?.groupID,
  };

  if (loading || !explorerData) {
    return null;
  }

  const occupancy = explorerData?.explorers?.groups?.[0]?.charts?.find(
    (e) => e.name === 'tiv_by_general_occupancy',
  );
  const occupancyChart = getChart(occupancy);
  const OccupancyExplorer = occupancyChart?.Explorer;

  const construction = explorerData?.explorers?.groups?.[0]?.charts?.find(
    (e) => e.name === 'tiv_by_construction_type_cluster',
  );
  const constructionChart = getChart(construction);
  const ConstructionExplorer = constructionChart?.Explorer;

  const geo = explorerData?.explorers?.groups?.[2]?.charts?.find(
    (e) => e.name === 'quality_geo_code_type',
  );

  const geoPie = { ...geo, __typename: 'TIVChart', values: geo.charts[0].values };

  const geoChart = getChart(geoPie);

  const GeoExplorer = geoChart?.Explorer;

  const aalSummary = explorerData?.explorers?.groups?.[3]?.charts?.find(
    (e) => e.name === 'aal_summary',
  );

  const aalData = JSON.parse(aalSummary?.json) || [];

  const aalScatterChart = getChart(aalSummary);
  const ScatterExplorer = aalScatterChart?.Explorer;

  const provenanceSummary = explorerData?.explorers?.groups?.[3]?.charts?.find(
    (e) => e.name === 'provenance_summary',
  );
  const provenanceChart = getChart(provenanceSummary);
  const ProvenanceExplorer = provenanceChart?.Explorer;

  const aalItems = aalData.filter((data) => data.peril !== 'Total');
  let totals: any = {};

  if (aalItems.length > 0) {
    totals = aalItems.reduce((acc, data) => {
      acc.latestPerilAAL = acc.latestPerilAAL || 0;
      acc.perilExposedTIV = acc.perilExposedTIV || 0;
      acc.lossRatio = acc.lossRatio || 0;

      acc.latestPerilAAL += data.latestPerilAAL;
      acc.perilExposedTIV += data.perilExposedTIV;
      acc.lossRatio += data.lossRatio;
      return acc;
    }, {});
    aalItems.push({
      latestPerilAAL: totals.latestPerilAAL,
      lossRatio: totals.lossRatio,
      peril: 'Sum',
      perilExposedTIV: totals.perilExposedTIV,
    });
  }

  return (
    <>
      <EuiSpacer size="xl" />
      <SummaryStats aalTotal={totals.latestPerilAAL} />
      <EuiSpacer size="xl" />
      <EuiSpacer size="xl" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <StyledDiv>
            <ReefMapV2
              streamSlug={stream.slug}
              group={group as any}
              indices={[]}
              // FIX ME
              // @ts-ignore
              pageState={{
                currentSnapshot: stream?.snapshots?.find((snapshot) => snapshot.visible)?.name,
              }}
            />
          </StyledDiv>
        </EuiFlexItem>
        <EuiFlexItem>
          {provenanceChart ? (
            <>
              <StyledContainer>
                <StyledChartTitle>Data Additions</StyledChartTitle>
              </StyledContainer>
              <EuiSpacer size="m" />
              <StyledContainer>
                <ProvenanceExplorer
                  explorer={provenanceSummary}
                  currentSnapshotLabel={''}
                  previousSnapshotLabel={''}
                  changeAnalysis={false}
                  title={null}
                />
              </StyledContainer>
            </>
          ) : (
            <LoadingSpinnerV2 />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xl" />
      <EuiFlexGroup>
        <EuiFlexItem>
          {aalScatterChart ? (
            <>
              <StyledContainer>
                <StyledChartTitle>AAL Loss Ratio (AAL / Exposed TIV)</StyledChartTitle>
              </StyledContainer>
              <EuiSpacer size="m" />
              <StyledContainer>
                <ScatterExplorer
                  explorer={aalSummary}
                  currentSnapshotLabel={''}
                  previousSnapshotLabel={''}
                  changeAnalysis={false}
                  title={null}
                />
              </StyledContainer>
            </>
          ) : (
            <LoadingSpinnerV2 />
          )}
        </EuiFlexItem>
        <EuiFlexItem>
          <ExposureByPeril aalData={aalItems} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xl" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFlexItem>
            {occupancyChart ? (
              <>
                <StyledContainer>
                  <StyledChartTitle>COPE - Occupancy</StyledChartTitle>
                </StyledContainer>
                <EuiSpacer size="m" />
                <StyledContainer>
                  <OccupancyExplorer
                    explorer={occupancy}
                    currentSnapshotLabel={''}
                    previousSnapshotLabel={''}
                    changeAnalysis={false}
                    title={null}
                  />
                </StyledContainer>
              </>
            ) : (
              <LoadingSpinnerV2 />
            )}
          </EuiFlexItem>
        </EuiFlexItem>
        <EuiFlexItem>
          {' '}
          {constructionChart ? (
            <>
              <StyledContainer>
                <StyledChartTitle>COPE - Construction</StyledChartTitle>
              </StyledContainer>
              <EuiSpacer size="m" />
              <StyledContainer>
                <ConstructionExplorer
                  explorer={construction}
                  currentSnapshotLabel={''}
                  previousSnapshotLabel={''}
                  changeAnalysis={false}
                  title={null}
                />
              </StyledContainer>
            </>
          ) : (
            <LoadingSpinnerV2 />
          )}
        </EuiFlexItem>
        <EuiFlexItem>
          {' '}
          {geoChart ? (
            <>
              <StyledContainer>
                <StyledChartTitle>COPE - Geocode Level</StyledChartTitle>
              </StyledContainer>
              <EuiSpacer size="m" />
              <StyledContainer>
                <GeoExplorer
                  explorer={geoPie}
                  currentSnapshotLabel={''}
                  previousSnapshotLabel={''}
                  changeAnalysis={false}
                  title={null}
                />
              </StyledContainer>
            </>
          ) : (
            <LoadingSpinnerV2 />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
