import React, { createContext, ReactNode, useContext, useState } from 'react';
import '@app/utils/arrays';
import { Message, SubscriptionMap, useSession } from './useSession';
import { UserChatInfoQuery, useUserChatInfoQuery } from '@app/graphql/queries/chat/__generated__/userChatInfo.generated';
import { UserChatInfo } from '@app/graphql/types';
import { ApolloQueryResult } from '@apollo/client';

export interface IChatContext {
  isOpen: boolean;
  open: () => void;
  close: () => void;

  roomSubscriptionsMap: SubscriptionMap;
  instructions: Array<Message>;
  setInstructions: (arr: Array<Message>) => void;
  sendMessage: (roomID: string, msg: string) => void;
  loading: boolean;

  instructorDMRoomID: string;
  user: UserChatInfo | null;
  refetch: () => Promise<ApolloQueryResult<UserChatInfoQuery>>
}

export const ChatContext = createContext<IChatContext>({
  close: null,
  instructions: [],
  instructorDMRoomID: '',
  isOpen: false,
  open: null,
  roomSubscriptionsMap: {},
  sendMessage: () => {},
  setInstructions: () => {},
  loading: false,
  user: null,
  refetch: () => {
    return {} as Promise<ApolloQueryResult<UserChatInfoQuery>>
  }
});

interface Props {
  children: ReactNode;
}

export const ChatProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const [instructions, setInstructions] = useState<Array<Message>>([]);

  const { loading, data, refetch } = useUserChatInfoQuery({
    onError: (err) => console.error('could not get user chat info', err),
  });

  const { roomSubscriptionsMap, sendMessage, instructorDMRoomID } = useSession({
    onNewMessage: (msg) => {
      if (msg?.customFields?.onReceive) {
        setInstructions((arr) => [...arr, msg]);
      }
    },
  });

  return (
    <ChatContext.Provider
      value={{
        close: () => setOpen(false),
        instructions,
        instructorDMRoomID,
        isOpen,
        open: () => setOpen(true),
        roomSubscriptionsMap,
        sendMessage,
        setInstructions,
        loading,
        user: data?.userChatInfo,
        refetch: refetch
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);
