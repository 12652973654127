import React, { useMemo } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { formatStreamCurrency } from '@app/cx/Stream/utils';
import { ValuationOutlierReason } from '@app/graphql/precheck/precheck.types';
import { useTracker } from '@app/hooks/useTracker';
import { FEATURE_TYPE_PRECHECK_VALUATIONS } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { colorMap } from './constants/colorMap';
import { labelMap } from './constants/labelMap';
import { useTaskContext } from './context/TaskContext';
import { AttritionalReason, CATReasons, COPEReason } from './context/types';
import { Check } from './Check';
import { Confetti } from './Confetti';
import {
  CompletedContainer,
  StyledBadgeContainer,
  StyledDescription,
  StyledExamples,
  StyledLoadContainer,
  StyledPanel,
  StyledPercent,
} from './DataCompleteness.emotion';
import { ConfettiContainer } from './RecommendationsV2.emotion';
import { StyledBadge } from './TaskTable.emotion';
export const formatPercentage = (tiv, totalTiv) => {
  const percent = (tiv / totalTiv) * 100;

  if (percent < 1 && percent > 0) {
    return percent.toFixed(2);
  } else {
    return Math.round(percent);
  }
};

export const DataCompleteness = () => {
  const { filters, totalTiv, taskTotals, loading, error } = useTaskContext();
  const tracker = useTracker();
  const { selectedOrganization } = useUserSession();

  const recordClick = (cardType) => {
    tracker.track(`Pre-Check: ${cardType} Completeness Card Clicked`);
  };

  const valuationOutliersEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_PRECHECK_VALUATIONS,
  );

  const reasonOptions = useMemo(() => {
    const options = [...filters.reasonOptions];
    const index = options.findIndex((item) => (item.value as CATReasons) === CATReasons.anyCat);
    if (index !== -1) {
      options.splice(index, 1);
    }

    return options;
  }, [filters.reasonOptions]);

  if (loading) {
    return (
      <StyledLoadContainer>
        <LoadingSpinnerV2 />
      </StyledLoadContainer>
    );
  }

  if (error) {
    console.error(error);
    return <div>Oops... Something went wrong, please try again</div>;
  }

  const copeActive = filters.reasons.includes(COPEReason.cope);
  const copeCompleted = taskTotals.cope.properties === 0;
  const availableReasonsString = filters.reasonOptions.map((reason) => reason.value);
  const cats = Object.values(CATReasons).filter((item) => availableReasonsString.includes(item));
  const outlierReasons = [
    ValuationOutlierReason.Overvalued,
    ValuationOutlierReason.Undervalued,
  ].filter((item) => availableReasonsString.includes(item));
  const isAnyCatAvailable = cats.length > 1;
  const catActive = isAnyCatAvailable
    ? filters.reasons.includes(CATReasons.anyCat)
    : cats.every((reason) => filters.reasons.includes(reason));
  const catCompleted = taskTotals.cat.properties === 0;
  const attrActive = filters.reasons.includes(AttritionalReason.attritional);
  const attrCompleted = taskTotals.attritional.properties === 0;
  const isAnyOutlierReasons = outlierReasons.length > 1;
  const outlierActive = isAnyOutlierReasons
    ? filters.reasons.includes(ValuationOutlierReason.AnyOutlier)
    : outlierReasons.every((reason) => filters.reasons.includes(reason));
  const outlierCompleted = taskTotals.valuation.properties === 0;

  const cards = [
    {
      badges: (
        <StyledBadge withMargin color={colorMap[COPEReason.cope]}>
          {labelMap[COPEReason.cope]}
        </StyledBadge>
      ),
      description: `COPE data provides the fundamental property information needed to assess catastrophe
            and all other peril risks.`,
      examples: `Examples: construction type, year built, fire protection`,
      isActive: copeActive,
      isCompleted: copeCompleted,
      percentText: `is missing relevant COPE data`,
      text: 'No COPE recommendations found.',
      type: 'cope',
    },
    {
      badges: reasonOptions.map((reason, i) =>
        cats.includes(reason.value as any) ? (
          <StyledBadge key={i} withMargin color={colorMap[reason.value]}>
            {labelMap[reason.value]}
          </StyledBadge>
        ) : null,
      ),
      description: `Catastrophe (CAT) secondary modifiers influence property vulnerability to perils
                such as earthquakes, floods, or hurricanes, affecting average annual loss models.`,
      examples: 'Examples: first floor elevation, roofing material',
      isActive: catActive,
      isCompleted: catCompleted,
      percentText: `is missing relevant CAT modifier data`,
      text: 'No CAT modifier recommendations found.',
      type: 'cat',
    },
    valuationOutliersEnabled
      ? {
          badges: (
            <>
              <StyledBadge withMargin color={colorMap[ValuationOutlierReason.Overvalued]}>
                Overvalued
              </StyledBadge>
              <StyledBadge withMargin color={colorMap[ValuationOutlierReason.Undervalued]}>
                Undervalued
              </StyledBadge>
            </>
          ),
          description: `Outliers are based on a properties current cost per floor area, compared to the average of similar properties.`,
          examples: 'Outliers are >1 standard deviation away from our benchmarks',
          isActive: outlierActive,
          isCompleted: outlierCompleted,
          percentText: `has a potential valuation outlier`,
          text: 'No valuation outliers found.',
          type: 'valuation',
        }
      : {
          badges: (
            <StyledBadge withMargin color={colorMap[AttritionalReason.attritional]}>
              {labelMap[AttritionalReason.attritional]}
            </StyledBadge>
          ),
          description: `Attritional attributes are a subset of COPE data that impact higher frequency, lower
                severity losses.`,
          examples: 'Examples: building condition, year built',
          isActive: attrActive,
          isCompleted: attrCompleted,
          percentText: `is missing relevant attritional data`,
          text: 'Attritional',
          type: 'attritional',
        },
  ];

  const applyFilter = (card) => {
    let activeValues = [];
    let inActiveValues = [];

    if (card.type === 'cope') {
      activeValues = filters.reasons.filter((r) => r !== COPEReason.cope);
      inActiveValues = [...filters.reasons, COPEReason.cope];
    }
    if (card.type === 'cat') {
      activeValues = filters.reasons.filter((r: CATReasons) => !cats.includes(r));
      if (isAnyCatAvailable) {
        inActiveValues = [...filters.reasons, CATReasons.anyCat];
      } else {
        inActiveValues = [...filters.reasons, ...cats];
      }
    }
    if (card.type === 'attritional') {
      activeValues = filters.reasons.filter((r) => r !== AttritionalReason.attritional);
      inActiveValues = [...filters.reasons, AttritionalReason.attritional];
    }

    if (card.type === 'valuation') {
      if (isAnyOutlierReasons) {
        activeValues = filters.reasons.filter((r) => r !== ValuationOutlierReason.AnyOutlier);
        inActiveValues = [...filters.reasons, ValuationOutlierReason.AnyOutlier];
      } else {
        activeValues = filters.reasons.filter(
          (r) =>
            r !== ValuationOutlierReason.Overvalued && r !== ValuationOutlierReason.Undervalued,
        );
        inActiveValues = [...filters.reasons, ...outlierReasons];
      }
    }

    if (card.isActive) {
      filters.setFilter('reasons', activeValues);
    } else {
      filters.setFilter('reasons', inActiveValues);
    }
  };

  return (
    <EuiFlexGroup wrap>
      {cards.map((card, i) =>
        card.isCompleted ? (
          <EuiFlexItem key={i} style={{ minWidth: '400px' }}>
            <CompletedContainer key={i} paddingSize="none" hasShadow={false}>
              <EuiFlexGroup direction="column" alignItems="center">
                <ConfettiContainer>
                  <Confetti />
                </ConfettiContainer>
                <Check />
                <EuiText>
                  <p>{card.text}</p>
                </EuiText>
              </EuiFlexGroup>
            </CompletedContainer>
          </EuiFlexItem>
        ) : (
          <EuiFlexItem key={i} style={{ minWidth: '400px' }}>
            <a
              style={{ color: 'unset' }}
              href="javascript:void(0)"
              onClick={(e) => {
                e.preventDefault();
                recordClick(card.type);
                applyFilter(card);
              }}
            >
              <StyledPanel active={card.isActive} hasShadow={false}>
                <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                  <StyledBadgeContainer>{card.badges}</StyledBadgeContainer>
                  <EuiText
                    style={{
                      color: '#0d6a64',
                      cursor: 'pointer',
                      fontSize: '0.7500rem',
                    }}
                  >
                    <a>{card.isActive ? 'Clear filter' : 'Click to filter'}</a>
                  </EuiText>
                </EuiFlexGroup>
                <EuiSpacer size="m" />
                <div style={{ minHeight: '50px' }}>
                  <EuiTitle size="xxs">
                    <h3>
                      <StyledPercent>
                        {formatPercentage(taskTotals[card.type].tiv, totalTiv)}%
                      </StyledPercent>
                      ({formatStreamCurrency(taskTotals[card.type].tiv)}) of total TIV{' '}
                      {card.percentText}
                    </h3>
                  </EuiTitle>
                </div>
                <EuiSpacer size="xs" />
                <StyledDescription>
                  <EuiText size="s">{card.description}</EuiText>
                </StyledDescription>
                <EuiSpacer size="xs" />
                <StyledExamples>{card.examples}</StyledExamples>
              </StyledPanel>
            </a>
          </EuiFlexItem>
        ),
      )}
    </EuiFlexGroup>
  );
};
