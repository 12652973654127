import React from 'react';
import styled from '@emotion/styled';
import { EuiCollapsibleNavGroup, EuiFlexGroup, EuiFlexItem, EuiShowFor, EuiSpacer } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { FEATURE_TYPE_CASUALTY_ONLY } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { CasualtyAppNav } from './NavGroups/CasualtyAppNav';
import { InternalSettings } from './NavGroups/InternalSettings';
import { MainAppNav } from './NavGroups/MainAppNav';
import { SignOut } from './NavGroups/SignOut';
import { NavLogo } from './NavLogo';
import { NavProvider, useNavContext } from './SideNavContext';

const StyledNav = styled.nav<{ isCollapsed: boolean; hasAlert: boolean }>`
  width: ${(props) => (props?.isCollapsed ? '50px' : '200px')};
  font-weight: 600;
  background-color: rgb(247, 248, 252);
  border-right: 1px solid #d3dae6;
  height: calc(100vh - ${(props) => (props?.hasAlert ? '97px' : '50px')});
  font-size: 14px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const StyledMobileNav = styled(EuiCollapsibleNavGroup)`
  width: 100%;
  font-weight: 600;
  font-size: 14px;
`;

const ResponsiveWrapper = ({ children, hasAlert }) => {
  const { isCollapsed } = useNavContext();

  return (
    <>
      <EuiShowFor sizes={['m', 'l', 'xl']}>
        <StyledNav isCollapsed={isCollapsed} hasAlert={hasAlert}>
          {children}
        </StyledNav>
      </EuiShowFor>
      <EuiShowFor sizes={['xs', 's']}>
        <StyledMobileNav
          background="light"
          title={<NavLogo />}
          iconSize="s"
          isCollapsible={true}
          initialIsOpen={false}
        >
          <div style={{ maxHeight: '50vh', overflow: 'auto' }}>{children}</div>
        </StyledMobileNav>
      </EuiShowFor>
    </>
  );
};

export const SideNavV2WithContext = ({ hasAlert }) => {
  const { selectedOrganization } = useUserSession();

  const isCasualtyApp = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_CASUALTY_ONLY,
  );

  if (!selectedOrganization) {
    return null;
  }

  return (
    <ResponsiveWrapper hasAlert={hasAlert}>
      <EuiSpacer size="m" />
      <EuiShowFor sizes={['m', 'l', 'xl']}>
        <NavLogo />
      </EuiShowFor>
      <EuiSpacer size="m" />
      <EuiFlexGroup
        style={{ height: `calc(100% - 60px)` }}
        justifyContent="spaceBetween"
        direction="column"
      >
        <EuiFlexItem grow={false}>
          {isCasualtyApp ? <CasualtyAppNav /> : <MainAppNav />}
          <InternalSettings />
        </EuiFlexItem>
        <EuiFlexItem style={{ justifyContent: 'flex-end', paddingBottom: '16px' }}>
          <SignOut />
        </EuiFlexItem>
      </EuiFlexGroup>
    </ResponsiveWrapper>
  );
};

export const SideNavV2 = ({ hasAlert }) => (
  <NavProvider>
    <SideNavV2WithContext hasAlert={hasAlert} />
  </NavProvider>
);
