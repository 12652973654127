import React from 'react';
import {
  Button,
  EuiFlexGroup,
  EuiFlexItem,
  EuiListGroup,
  EuiListGroupItem,
  EuiPanel,
  EuiTitle,
} from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUpdateSupportTicketMutation } from '@app/graphql/mutations/support/__generated__/updateSupportTicket.generated';
import { GET_SUPPORT_TICKET } from '@app/graphql/queries/support/getSupportTicket';
import { SupportTicket, TicketPriority } from '@app/graphql/types';

interface Props {
  ticket: SupportTicket;
}

export const TicketPreview: React.FC<Props> = ({ ticket }) => {
  const {
    account: { email },
  } = useAuth();

  const [updateTicket] = useUpdateSupportTicketMutation({
    refetchQueries: [
      { query: GET_SUPPORT_TICKET, variables: { input: { id: ticket.chatRoomID } } },
    ],
  });

  const assignSelf = () => {
    updateTicket({
      variables: {
        input: {
          assigneeEmails: [...ticket.assigneeEmails, email],
          chatRoomID: ticket.chatRoomID,
          priority: ticket.priority || TicketPriority.None,
          status: ticket.status,
          followerEmails: [...(ticket?.followerEmails || [])],
        },
      },
    });
  };

  return (
    <EuiPanel paddingSize="xl">
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiTitle>
                <h2>{ticket.title}</h2>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <Button onClick={assignSelf} label="Assign to myself" />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>Initial message here</EuiFlexItem>
        <EuiFlexItem>Created by {ticket.authorEmail} on 02-01-2024</EuiFlexItem>
        <EuiFlexItem>Assign yourself to this ticket to join the conversation</EuiFlexItem>
        <EuiFlexItem>
          Assigned users:
          <EuiListGroup title="Assigned support users">
            {ticket.assigneeEmails?.map((e) => (
              <EuiListGroupItem label={e} />
            ))}
          </EuiListGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};
