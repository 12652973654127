import React, { useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  Button,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiTitle,
} from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import {
  OrganizationActivitiesQuery,
  useOrganizationActivitiesQuery,
} from '@app/graphql/queries/organizationActivities/__generated__/organizationActivities.generated';
import { ActivityEvent } from '@app/graphql/types';
import EmptyMessage from '../EmptyMessage/EmptyMessage';
import { AttributesEdited, Properties } from '../HomePage/ActivityFeed/EventsByDate';
import { StyledProperty } from './ActivitySummary.emotion';

export const ActivitySummary = () => {
  const { selectedOrganization } = useUserSession();

  const [items, setItems] = useState<
    OrganizationActivitiesQuery['organizationActivities']['activities']
  >([]);

  const { data, loading, refetch, networkStatus } = useOrganizationActivitiesQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setItems((prevState) => [...prevState, ...(data?.organizationActivities?.activities || [])]);
    },
    variables: {
      input: {
        cursor: null,
        limit: 3,
        organizationName: selectedOrganization.name,
      },
    },
  });

  const isLoading =
    loading || networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore;

  const loadMore = async () => {
    await refetch({
      input: {
        cursor: data?.organizationActivities?.cursor,
        limit: 10,
        organizationName: selectedOrganization.name,
      },
    });
  };

  const columns: Array<EuiBasicTableColumn<ActivityEvent>> = [
    {
      field: 'attributes',
      name: '',
      render: (attributes: string, eventInfo) => (
        <EuiFlexGroup direction="column" justifyContent="flexStart" gutterSize="none">
          <EuiFlexItem grow={false}>
            <AttributesEdited attributes={attributes} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <StyledProperty>
              <Properties
                properties={eventInfo.properties}
                propertiesCount={eventInfo.propertiesCount}
                streamSlugRandomIdentifier={eventInfo.streamSlugRandomIdentifier}
              />
            </StyledProperty>
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
    {
      field: 'email',
      name: '',
      render: (email: string, eventInfo) => {
        const date = new Date(eventInfo.timestamp);

        const formattedDate = date.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });

        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          hour12: true,
          minute: '2-digit',
        });

        return (
          <EuiFlexGroup direction="column" justifyContent="flexEnd" gutterSize="none">
            <EuiFlexItem>{email}</EuiFlexItem>
            <EuiFlexItem grow={false}>{`${formattedDate} @ ${formattedTime}`}</EuiFlexItem>
          </EuiFlexGroup>
        );
      },
    },
  ];

  if (items.length === 0 && !isLoading) {
    return (
      <EuiPanel paddingSize="l">
        <EuiTitle size="xs">
          <p>Recent Activity</p>
        </EuiTitle>
        <EmptyMessage subText="No recent activity on accounts" />
      </EuiPanel>
    );
  }

  return (
    <EuiPanel paddingSize="l">
      <EuiTitle size="xs">
        <p>Recent Activity</p>
      </EuiTitle>
      <EuiBasicTable
        style={{ overflowY: 'auto' }}
        columns={columns}
        loading={isLoading}
        // @ts-ignore
        items={items}
      />
      <EuiFlexItem data-testid="activity-view-more" grow={false}>
        <Button
          onClick={() => {
            loadMore();
          }}
          size="s"
          label={'View More'}
        />
      </EuiFlexItem>
    </EuiPanel>
  );
};
