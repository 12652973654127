import React, { useCallback, useEffect, useRef } from 'react';
import { ROCKET_CHAT_URL } from '@app/config';
import { useChatContext } from '../Chat/ChatProvider';
import { RocketChatContainer } from './RocketChat.emotion';

interface Props {
  room: string;
  initialRoom: string;
  // Event that is triggered when the user has sent a message
  onUserSentMessage?: () => void;
}
export const RocketChat: React.FC<Props> = ({ room, initialRoom, onUserSentMessage }) => {
  const frame: { current: HTMLIFrameElement } = useRef();
  const { user, refetch } = useChatContext();
  const targetOrigin = ROCKET_CHAT_URL;

  const onLoginToChat = useCallback(
    (token: string) => {
      if (frame.current) {
        const contentWindow = frame.current.contentWindow;
        contentWindow.focus();
        contentWindow.postMessage(
          {
            externalCommand: 'login-with-token',
            token: token,
          },
          targetOrigin,
        );
      }
    },
    [],
  )

  const onRefetchToken = useCallback(
    async() => {
      try {
        const { data } = await refetch();
        onLoginToChat(data?.userChatInfo?.token);
      } catch (error) {
        console.log(error, 'error')
      }
    },
    [refetch],
  )

  const handleChatEvent = useCallback((e) => {
    if (!frame.current?.contentWindow || !e.origin.includes(ROCKET_CHAT_URL)) {
      return;
    }

    if (e.data.eventName === 'new-message') {
      if (e?.data?.data?.u?._id === user?.userID && !!onUserSentMessage) {
        // The user that is logged in is the one that sent the message
        onUserSentMessage();
      }
    }

    if (e.data.eventName === 'startup') {
      onLoad();
    }

    if (e?.data?.eventName === 'Custom_Script_Logged_Out') {
      console.log('in')
      onRefetchToken();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleChatEvent);

    return () => {
      window.removeEventListener('message', handleChatEvent);
    };
  }, []);

  useEffect(() => {
    if (frame.current) {
      frame.current.contentWindow.postMessage(
        {
          externalCommand: 'go',
          path: `/group/${room}`,
        },
        targetOrigin,
      );
    }
  }, [room]);

  const onLoad = () => {
    onLoginToChat(user?.token);
  };

  return (
    <RocketChatContainer
      id="rocket-chat"
      ref={frame}
      src={`${ROCKET_CHAT_URL}/group/${initialRoom}?layout=embedded`}
      allow="camera;microphone"
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      onLoad={onLoad}
    />
  );
};
