import React from 'react';
import styled from '@emotion/styled';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from 'ui';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import Currency from '../Currency/Currency';

export const StyledContainer = styled.div`
  text-align: center;
`;

export const StyledTitle = styled.h3`
  color: rgb(70, 78, 86);
  font-size: 15px;
  font-weight: 400;
`;

export const StyledStat = styled.span`
  color: rgb(0, 0, 0);
  font-size: 30px;
  font-weight: 700;
`;

export const SummaryStats = ({ aalTotal }) => {
  const { stream } = useStreamContext();

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <StyledContainer>
          <StyledTitle>Properties</StyledTitle>
        </StyledContainer>
        <EuiSpacer size="m" />
        <StyledContainer>
          <StyledStat>{stream.propertiesCount || '-'}</StyledStat>
        </StyledContainer>
      </EuiFlexItem>
      <EuiFlexItem>
        <StyledContainer>
          <StyledTitle>TIV</StyledTitle>
        </StyledContainer>
        <EuiSpacer size="m" />
        <StyledContainer>
          <StyledStat>
            <Currency value={stream.totalInsuredValue} />
          </StyledStat>
        </StyledContainer>
      </EuiFlexItem>
      <EuiFlexItem>
        <StyledContainer>
          <StyledTitle>Total AAL</StyledTitle>
        </StyledContainer>
        <EuiSpacer size="m" />
        <StyledContainer>
          <StyledStat>
            <Currency value={aalTotal} />
          </StyledStat>
        </StyledContainer>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
